var data = {
    "0": {
        "param_order": [
            "0", 
            "1", 
            "2", 
            "3", 
            "4", 
            "5", 
            "6", 
            "7", 
            "8", 
            "9", 
            "10", 
            "11", 
            "12", 
            "13", 
            "14", 
            "15", 
            "16", 
            "17", 
            "18", 
            "19", 
            "20", 
            "21", 
            "22", 
            "23", 
            "25", 
            "26", 
            "27", 
            "28", 
            "29", 
            "30", 
            "31", 
            "32", 
            "33", 
            "34", 
            "35", 
            "36", 
            "37", 
            "38", 
            "39", 
            "40", 
            "41", 
            "42", 
            "43", 
            "44", 
            "45", 
            "46", 
            "47", 
            "48", 
            "49", 
            "50", 
            "51", 
            "52", 
            "53", 
            "54", 
            "55", 
            "56", 
            "57", 
            "58", 
            "59", 
            "60", 
            "61", 
            "62", 
            "63", 
            "64", 
            "65", 
            "66", 
            "67", 
            "68", 
            "69", 
            "70", 
            "71", 
            "72", 
            "73", 
            "74", 
            "75", 
            "76", 
            "77", 
            "78", 
            "79", 
            "80", 
            "81", 
            "82", 
            "83", 
            "84"
        ], 
        "parameters": {
            "0": {
                "name": {
                    "label": "durum", 
                    "translation": {
                        "en": "status", 
                        "tr": "durum"
                    }, 
                    "value": "0"
                }, 
                "options": [
                    {
                        "label": "pasif", 
                        "translation": {
                            "en": "passive", 
                            "tr": "pasif"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "aktif", 
                        "translation": {
                            "en": "active", 
                            "tr": "aktif"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "1": {
                "name": {
                    "label": "menü arka plan rengi", 
                    "translation": {
                        "en": "menu background color", 
                        "tr": "menü arka plan rengi"
                    }, 
                    "value": "1"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "10": {
                "name": {
                    "label": "navbar uygulama adı yazı gölge rengi", 
                    "translation": {
                        "en": "navbar application name shadow color", 
                        "tr": "navbar uygulama adı yazı gölge rengi"
                    }, 
                    "value": "10"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "11": {
                "name": {
                    "label": "login arka plan rengi", 
                    "translation": {
                        "en": "login background color", 
                        "tr": "login arka plan rengi"
                    }, 
                    "value": "11"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "12": {
                "name": {
                    "label": "login kard dış arka plan rengi", 
                    "translation": {
                        "en": "login card out background color", 
                        "tr": "login kard dış arka plan rengi"
                    }, 
                    "value": "12"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "13": {
                "name": {
                    "label": "login kard dış gölge rengi", 
                    "translation": {
                        "en": "login card out shadow color", 
                        "tr": "login kard dış gölge rengi"
                    }, 
                    "value": "13"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "14": {
                "name": {
                    "label": "login iç dış arka plan rengi", 
                    "translation": {
                        "en": "login card in background color", 
                        "tr": "login iç dış arka plan rengi"
                    }, 
                    "value": "14"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "15": {
                "name": {
                    "label": "login iç dış gölge rengi", 
                    "translation": {
                        "en": "login card in shadow color", 
                        "tr": "login iç dış gölge rengi"
                    }, 
                    "value": "15"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "16": {
                "name": {
                    "label": "login butonlar arka plan rengi", 
                    "translation": {
                        "en": "login buttons background color", 
                        "tr": "login butonlar arka plan rengi"
                    }, 
                    "value": "16"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "17": {
                "name": {
                    "label": "login girdi alanları sınır rengi", 
                    "translation": {
                        "en": "login inputs border color", 
                        "tr": "login girdi alanları sınır rengi"
                    }, 
                    "value": "17"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "18": {
                "name": {
                    "label": "login loading arka plan rengi", 
                    "translation": {
                        "en": "login loading background color", 
                        "tr": "login loading arka plan rengi"
                    }, 
                    "value": "18"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "19": {
                "name": {
                    "label": "login slogan text", 
                    "translation": {
                        "en": "login slogan text", 
                        "tr": "login slogan text"
                    }, 
                    "value": "19"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "2": {
                "name": {
                    "label": "menü alt sınır rengi", 
                    "translation": {
                        "en": "menu border bottom color", 
                        "tr": "menü alt sınır rengi"
                    }, 
                    "value": "2"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "20": {
                "name": {
                    "label": "uygulama adı", 
                    "translation": {
                        "en": "application name", 
                        "tr": "uygulama adı"
                    }, 
                    "value": "20"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "21": {
                "name": {
                    "label": "navbar orta logo", 
                    "translation": {
                        "en": "navbar middle logo", 
                        "tr": "navbar orta logo"
                    }, 
                    "value": "21"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "image"
            }, 
            "22": {
                "name": {
                    "label": "menü üst birim başlık", 
                    "translation": {
                        "en": "menu top caption", 
                        "tr": "menü üst birim başlık"
                    }, 
                    "value": "22"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "23": {
                "name": {
                    "label": "meta", 
                    "translation": {
                        "en": "meta", 
                        "tr": "meta"
                    }, 
                    "value": "23"
                }, 
                "param_order": [
                    "24"
                ], 
                "parameters": {
                    "24": {
                        "name": {
                            "label": "meta değeri", 
                            "translation": {
                                "en": "meta value", 
                                "tr": "meta değeri"
                            }, 
                            "value": "24"
                        }, 
                        "prefix": "", 
                        "show": "yes", 
                        "type": "text"
                    }
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "list_parameter"
            }, 
            "25": {
                "name": {
                    "label": "wmanager uri", 
                    "translation": {
                        "en": "wmanager uri", 
                        "tr": "wmanager uri"
                    }, 
                    "value": "25"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "26": {
                "name": {
                    "label": "login fonksiyonu", 
                    "translation": {
                        "en": "login function", 
                        "tr": "login fonksiyonu"
                    }, 
                    "value": "26"
                }, 
                "options": [
                    {
                        "label": "hayır", 
                        "translation": {
                            "en": "no", 
                            "tr": "hayır"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "evet", 
                        "translation": {
                            "en": "yes", 
                            "tr": "evet"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "27": {
                "name": {
                    "label": "eksternal login fonksiyonu", 
                    "translation": {
                        "en": "login function external", 
                        "tr": "eksternal login fonksiyonu"
                    }, 
                    "value": "27"
                }, 
                "options": [
                    {
                        "label": "hayır", 
                        "translation": {
                            "en": "no", 
                            "tr": "hayır"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "evet", 
                        "translation": {
                            "en": "yes", 
                            "tr": "evet"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "28": {
                "name": {
                    "label": "menü tipi", 
                    "translation": {
                        "en": "menu type", 
                        "tr": "menü tipi"
                    }, 
                    "value": "28"
                }, 
                "options": [
                    {
                        "label": "sol menü", 
                        "translation": {
                            "en": "left menu", 
                            "tr": "sol menü"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "üst menü", 
                        "translation": {
                            "en": "top menu", 
                            "tr": "üst menü"
                        }, 
                        "value": "1"
                    }, 
                    {
                        "label": "sol menü açık", 
                        "translation": {
                            "en": "left menu open", 
                            "tr": "sol menü açık"
                        }, 
                        "value": "2"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "29": {
                "name": {
                    "label": "wanalyzer api url", 
                    "translation": {
                        "en": "wanalyzer api url", 
                        "tr": "wanalyzer api url"
                    }, 
                    "value": "29"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "3": {
                "name": {
                    "label": "menü kenar gölgesi rengi", 
                    "translation": {
                        "en": "menu box shadow color", 
                        "tr": "menü kenar gölgesi rengi"
                    }, 
                    "value": "3"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "30": {
                "name": {
                    "label": "wanalyzer api kullanıcı adı", 
                    "translation": {
                        "en": "wanalyzer api username", 
                        "tr": "wanalyzer api kullanıcı adı"
                    }, 
                    "value": "30"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "31": {
                "name": {
                    "label": "wanalyzer api şifre", 
                    "translation": {
                        "en": "wanalyzer api password", 
                        "tr": "wanalyzer api şifre"
                    }, 
                    "value": "31"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "32": {
                "name": {
                    "label": "wanalyzer frontend url", 
                    "translation": {
                        "en": "wanalyzer frontend url", 
                        "tr": "wanalyzer frontend url"
                    }, 
                    "value": "32"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "33": {
                "name": {
                    "label": "mağaza sayfaları aktif mi", 
                    "translation": {
                        "en": "are shop pages active", 
                        "tr": "mağaza sayfaları aktif mi"
                    }, 
                    "value": "33"
                }, 
                "options": [
                    {
                        "label": "hayır", 
                        "translation": {
                            "en": "no", 
                            "tr": "hayır"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "evet", 
                        "translation": {
                            "en": "yes", 
                            "tr": "evet"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "34": {
                "name": {
                    "label": "worganization api url", 
                    "translation": {
                        "en": "worganization api url", 
                        "tr": "worganization api url"
                    }, 
                    "value": "34"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "35": {
                "name": {
                    "label": "worganization api kullanıcı adı", 
                    "translation": {
                        "en": "worganization api username", 
                        "tr": "worganization api kullanıcı adı"
                    }, 
                    "value": "35"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "36": {
                "name": {
                    "label": "worganization api şifre", 
                    "translation": {
                        "en": "worganization api password", 
                        "tr": "worganization api şifre"
                    }, 
                    "value": "36"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "37": {
                "name": {
                    "label": "worganization frontend url", 
                    "translation": {
                        "en": "worganization frontend url", 
                        "tr": "worganization frontend url"
                    }, 
                    "value": "37"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "38": {
                "name": {
                    "label": "worganization token", 
                    "translation": {
                        "en": "worganization token", 
                        "tr": "worganization token"
                    }, 
                    "value": "38"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "39": {
                "name": {
                    "label": "worganization organizasyon id değerleri", 
                    "translation": {
                        "en": "worganization organizasyon id values", 
                        "tr": "worganization organizasyon id değerleri"
                    }, 
                    "value": "39"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "4": {
                "name": {
                    "label": "menü ana başlık yazı rengi", 
                    "translation": {
                        "en": "menu parent item text color", 
                        "tr": "menü ana başlık yazı rengi"
                    }, 
                    "value": "4"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "40": {
                "name": {
                    "label": "kullanım şartları linki", 
                    "translation": {
                        "en": "terms of service link", 
                        "tr": "kullanım şartları linki"
                    }, 
                    "value": "40"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "41": {
                "name": {
                    "label": "gizlilik politikası linki", 
                    "translation": {
                        "en": "privacy policy link", 
                        "tr": "gizlilik politikası linki"
                    }, 
                    "value": "41"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "42": {
                "name": {
                    "label": "kayıt mail onay frontend url ve route", 
                    "translation": {
                        "en": "register mail approve frontend uri and route", 
                        "tr": "kayıt mail onay frontend url ve route"
                    }, 
                    "value": "42"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "43": {
                "name": {
                    "label": "kayıt mail onay konu", 
                    "translation": {
                        "en": "register mail approve subject", 
                        "tr": "kayıt mail onay konu"
                    }, 
                    "value": "43"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "44": {
                "name": {
                    "label": "kayıt mail onay gönderen mail", 
                    "translation": {
                        "en": "register mail approve mail from", 
                        "tr": "kayıt mail onay gönderen mail"
                    }, 
                    "value": "44"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "45": {
                "name": {
                    "label": "kayıt mail onay gönderen mail şifre", 
                    "translation": {
                        "en": "register mail approve mail from password", 
                        "tr": "kayıt mail onay gönderen mail şifre"
                    }, 
                    "value": "45"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "46": {
                "name": {
                    "label": "kayıt mail onay link metni", 
                    "translation": {
                        "en": "register mail approve link text", 
                        "tr": "kayıt mail onay link metni"
                    }, 
                    "value": "46"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "47": {
                "name": {
                    "label": "mail api token url", 
                    "translation": {
                        "en": "mail api token uri", 
                        "tr": "mail api token url"
                    }, 
                    "value": "47"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "48": {
                "name": {
                    "label": "mail api username", 
                    "translation": {
                        "en": "mail api token username", 
                        "tr": "mail api username"
                    }, 
                    "value": "48"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "49": {
                "name": {
                    "label": "mail api password", 
                    "translation": {
                        "en": "mail api token password", 
                        "tr": "mail api password"
                    }, 
                    "value": "49"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "5": {
                "name": {
                    "label": "menü ana başlık alt sınır rengi", 
                    "translation": {
                        "en": "menu parent item border bottom color", 
                        "tr": "menü ana başlık alt sınır rengi"
                    }, 
                    "value": "5"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "50": {
                "name": {
                    "label": "mail api send mail url", 
                    "translation": {
                        "en": "mail api send mail uri", 
                        "tr": "mail api send mail url"
                    }, 
                    "value": "50"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "51": {
                "name": {
                    "label": "şifre sıfırlama mail onay frontend url ve route", 
                    "translation": {
                        "en": "password reset mail approve frontend uri and route", 
                        "tr": "şifre sıfırlama mail onay frontend url ve route"
                    }, 
                    "value": "51"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "52": {
                "name": {
                    "label": "şifre sıfırlama mail onay konu", 
                    "translation": {
                        "en": "password reset mail approve subject", 
                        "tr": "şifre sıfırlama mail onay konu"
                    }, 
                    "value": "52"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "53": {
                "name": {
                    "label": "şifre sıfırlama mail onay link metni", 
                    "translation": {
                        "en": "password reset mail approve link text", 
                        "tr": "şifre sıfırlama mail onay link metni"
                    }, 
                    "value": "53"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "54": {
                "name": {
                    "label": "waccount anahtarı", 
                    "translation": {
                        "en": "waccount external key", 
                        "tr": "waccount anahtarı"
                    }, 
                    "value": "54"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "55": {
                "name": {
                    "label": "waccount link", 
                    "translation": {
                        "en": "waccount url", 
                        "tr": "waccount link"
                    }, 
                    "value": "55"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "56": {
                "name": {
                    "label": "waccount kullanıcı adı", 
                    "translation": {
                        "en": "waccount username", 
                        "tr": "waccount kullanıcı adı"
                    }, 
                    "value": "56"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "57": {
                "name": {
                    "label": "waccount şifre", 
                    "translation": {
                        "en": "waccount password", 
                        "tr": "waccount şifre"
                    }, 
                    "value": "57"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "58": {
                "name": {
                    "label": "waccount sistem ürün id", 
                    "translation": {
                        "en": "waccount system product id", 
                        "tr": "waccount sistem ürün id"
                    }, 
                    "value": "58"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "59": {
                "name": {
                    "label": "kayıt fonksiyonu", 
                    "translation": {
                        "en": "register function", 
                        "tr": "kayıt fonksiyonu"
                    }, 
                    "value": "59"
                }, 
                "options": [
                    {
                        "label": "hayır", 
                        "translation": {
                            "en": "no", 
                            "tr": "hayır"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "evet", 
                        "translation": {
                            "en": "yes", 
                            "tr": "evet"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "6": {
                "name": {
                    "label": "menü ana başlık arka plan rengi", 
                    "translation": {
                        "en": "menu parent item background color", 
                        "tr": "menü ana başlık arka plan rengi"
                    }, 
                    "value": "6"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "60": {
                "name": {
                    "label": "worganization kullanıcı id", 
                    "translation": {
                        "en": "worganization user id", 
                        "tr": "worganization kullanıcı id"
                    }, 
                    "value": "60"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "61": {
                "name": {
                    "label": "uygulama frontend url", 
                    "translation": {
                        "en": "application frontend uri", 
                        "tr": "uygulama frontend url"
                    }, 
                    "value": "61"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "62": {
                "name": {
                    "label": "uygulama backend url", 
                    "translation": {
                        "en": "application backend uri", 
                        "tr": "uygulama backend url"
                    }, 
                    "value": "62"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "63": {
                "name": {
                    "label": "destek detay konu", 
                    "translation": {
                        "en": "support details subject", 
                        "tr": "destek detay konu"
                    }, 
                    "value": "63"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "64": {
                "name": {
                    "label": "destek gönderen mail", 
                    "translation": {
                        "en": "support mail from", 
                        "tr": "destek gönderen mail"
                    }, 
                    "value": "64"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "65": {
                "name": {
                    "label": "destek gönderen mail şifre", 
                    "translation": {
                        "en": "support mail from password", 
                        "tr": "destek gönderen mail şifre"
                    }, 
                    "value": "65"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "66": {
                "name": {
                    "label": "destek detay link metni", 
                    "translation": {
                        "en": "support details link text", 
                        "tr": "destek detay link metni"
                    }, 
                    "value": "66"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "67": {
                "name": {
                    "label": "destek detay frontend url ve route", 
                    "translation": {
                        "en": "support details frontend uri and route", 
                        "tr": "destek detay frontend url ve route"
                    }, 
                    "value": "67"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "68": {
                "name": {
                    "label": "navbar arka plan rengi", 
                    "translation": {
                        "en": "navbar background color", 
                        "tr": "navbar arka plan rengi"
                    }, 
                    "value": "68"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "69": {
                "name": {
                    "label": "navbar uygulama adı rengi", 
                    "translation": {
                        "en": "navbar application name color", 
                        "tr": "navbar uygulama adı rengi"
                    }, 
                    "value": "69"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "7": {
                "name": {
                    "label": "menü alt başlık yazı rengi", 
                    "translation": {
                        "en": "menu child item text color", 
                        "tr": "menü alt başlık yazı rengi"
                    }, 
                    "value": "7"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "70": {
                "name": {
                    "label": "sayfa alanı yazı rengi", 
                    "translation": {
                        "en": "page area text color", 
                        "tr": "sayfa alanı yazı rengi"
                    }, 
                    "value": "70"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "71": {
                "name": {
                    "label": "footer yazı rengi", 
                    "translation": {
                        "en": "footer text color", 
                        "tr": "footer yazı rengi"
                    }, 
                    "value": "71"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "72": {
                "name": {
                    "label": "footer arkaplan rengi", 
                    "translation": {
                        "en": "footer background color", 
                        "tr": "footer arkaplan rengi"
                    }, 
                    "value": "72"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "73": {
                "name": {
                    "label": "navbar yazı rengi", 
                    "translation": {
                        "en": "navbar text color", 
                        "tr": "navbar yazı rengi"
                    }, 
                    "value": "73"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "74": {
                "name": {
                    "label": "facebook link", 
                    "translation": {
                        "en": "facebook link", 
                        "tr": "facebook link"
                    }, 
                    "value": "74"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "75": {
                "name": {
                    "label": "twitter link", 
                    "translation": {
                        "en": "twitter link", 
                        "tr": "twitter link"
                    }, 
                    "value": "75"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "76": {
                "name": {
                    "label": "instagram link", 
                    "translation": {
                        "en": "instagram link", 
                        "tr": "instagram link"
                    }, 
                    "value": "76"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "77": {
                "name": {
                    "label": "linkedin_link", 
                    "translation": {
                        "en": "linkedin_link", 
                        "tr": "linkedin_link"
                    }, 
                    "value": "77"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "78": {
                "name": {
                    "label": "login arka plan resmi", 
                    "translation": {
                        "en": "login background image", 
                        "tr": "login arka plan resmi"
                    }, 
                    "value": "78"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "79": {
                "name": {
                    "label": "mail gizlilik bildirimi metni", 
                    "translation": {
                        "en": "mail privacy notice text", 
                        "tr": "mail gizlilik bildirimi metni"
                    }, 
                    "value": "79"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "8": {
                "name": {
                    "label": "menü alt başlık alt sınır rengi", 
                    "translation": {
                        "en": "menu child item border bottom color", 
                        "tr": "menü alt başlık alt sınır rengi"
                    }, 
                    "value": "8"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "80": {
                "name": {
                    "label": "mail smtp adres", 
                    "translation": {
                        "en": "mail smtp address", 
                        "tr": "mail smtp adres"
                    }, 
                    "value": "80"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "81": {
                "name": {
                    "label": "mail smtp kullanıcı adı", 
                    "translation": {
                        "en": "mail smtp username", 
                        "tr": "mail smtp kullanıcı adı"
                    }, 
                    "value": "81"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "82": {
                "name": {
                    "label": "mail smtp şifre", 
                    "translation": {
                        "en": "mail smtp password", 
                        "tr": "mail smtp şifre"
                    }, 
                    "value": "82"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "83": {
                "name": {
                    "label": "footer tarihi", 
                    "translation": {
                        "en": "footer date", 
                        "tr": "footer tarihi"
                    }, 
                    "value": "83"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "84": {
                "name": {
                    "label": "navbar akan yazı", 
                    "translation": {
                        "en": "navbar flowing text", 
                        "tr": "navbar akan yazı"
                    }, 
                    "value": "84"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "9": {
                "name": {
                    "label": "menü alt başlık arka plan rengi", 
                    "translation": {
                        "en": "menu child item background color", 
                        "tr": "menü alt başlık arka plan rengi"
                    }, 
                    "value": "9"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }
        }
    }, 
    "about": [], 
    "bmi_gfr": "no", 
    "calculate_eval": "no", 
    "checked_on_open": "no", 
    "date_ability": "yes", 
    "date_ability_limit": "none", 
    "date_ability_type": "date", 
    "date_ability_user_selection": "no", 
    "document_upload": "no", 
    "ending_date_ability": "yes", 
    "ending_date_ability_limit": "none", 
    "ending_date_ability_type": "date", 
    "ending_date_ability_user_selection": "no", 
    "favorite_show_mode": "yes", 
    "language": {
        "label": "Türkçe", 
        "value": "tr"
    }, 
    "model": "wisdom_data", 
    "module": [], 
    "name": {
        "label": "wdm_name !!!", 
        "translation": {
            "en": "frontend", 
            "tr": "frontend"
        }, 
        "value": "ly + Created Layer Id !!!"
    }, 
    "owner": {
        "label": "katman", 
        "value": "layer"
    }, 
    "owner_type": "layer", 
    "param_group": {
        "param_order": [
            "0"
        ], 
        "parameters": {
            "0": {
                "label": "Param", 
                "value": "0"
            }
        }
    }, 
    "parent": "ly + Created Layer Id", 
    "record_count": "many_times", 
    "status": "published", 
    "style": {
        "caption": {
            "after": "", 
            "backgroundColor": "white", 
            "border": {
                "color": "black", 
                "size": 0, 
                "type": "solid"
            }, 
            "borderRadius": 0, 
            "color": "black", 
            "fontSize": 12
        }, 
        "value": {
            "after": "", 
            "backgroundColor": "white", 
            "border": {
                "color": "black", 
                "size": 0, 
                "type": "solid"
            }, 
            "borderRadius": 0, 
            "color": "black", 
            "fontSize": 10
        }
    }, 
    "value": "ly + Created Layer Id", 
    "version": "1.0"
};
export { data }
